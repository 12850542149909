import { useContext } from "react"
import { Link, NavLink } from "react-router-dom"
import { Context } from "../context/UserContext"


export default function SideBar(){
    const {logout} = useContext(Context)
    return (
        <div className="bg-primary px-1 px-md-3  py-4 h-100 d-flex flex-column justify-content-between">
        <ul className="nav nav-pills flex-column">
            <li className="nav-item px-1 mt-1 rounded-5">
                <Link className='text-decoration-none border border-white p-1 m-0 fs-6 rounded-5 d-flex align-items-center' to="/admin/profile">
                    <img className="rounded-5 object-fit-cover" src="https://as1.ftcdn.net/v2/jpg/03/53/11/00/1000_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg" style={{width: '50px', height: '50px'}} alt="user_photo" />
                    <div className="ms-2">
                        <h5 className="text-white fw-normal text-uppercase m-0">Pedro Sérgio</h5>
                        <small className="text-info m-0">CEO</small>
                    </div>
                </Link>
            </li>
            <li className="nav-item px-1 mt-4 rounded-5"><NavLink className='nav-link m-0 fs-5 rounded-5 d-flex align-items-center' to="/admin" end >
                <i className="material-icons-round d-flex me-1">inventory</i>ESTOQUE</NavLink>
            </li>
            <li className="nav-item px-1 mt-2 rounded-5"><NavLink className='nav-link m-0 fs-5 rounded-5 d-flex align-items-center' to="/admin/insights">
                <i className="material-icons-round d-flex me-1">equalizer</i>INSIGHTS</NavLink>
            </li>
            <li className="nav-item px-1 mt-2 rounded-5"><NavLink className='nav-link m-0 fs-5 rounded-5 d-flex align-items-center' to="/admin/banner">
                <i className="material-icons-round d-flex me-1">aspect_ratio</i>BANNERS</NavLink>
            </li>
            <li className="nav-item px-1 mt-2 rounded-5"><NavLink className='nav-link m-0 fs-5 rounded-5 d-flex align-items-center' to="/admin/sellers">
                <i className="material-icons-round d-flex me-1">assignment_ind</i>VENDEDORES</NavLink>
            </li>
        </ul>
        <div className="">
            <hr className="text-white"/>
            <button onClick={() => logout()} className="text-white btn btn-link p-0 text-decoration-none w-100 text-center d-flex justify-content-center"> 
                <span  className="material-icons-round d-flex">logout</span>SAIR
            </button>
        </div>
        </div>
    )
}