import { useContext, useEffect, useState } from 'react'
import background from '../../assets/background/fundoNewProduct.png'
import backgroundMobile from '../../assets/background/editing.png'
import Input from '../../components/Input'
import ApiService from '../../api/apiService'
import Select from '../../components/Select'
import { Context } from '../../context/UserContext'
import { useNavigate, useParams } from 'react-router-dom'

const types = [
    {
        name: "Camisetas",
        value: "tshirt"
    },
    {
        name: "Shorts",
        value: "shorts"
    },
    {
        name: "Calças",
        value: "pants"
    },
    {
        name: "Calçados",
        value: "footwear"
    },
    {
        name: "Acessórios",
        value: "accessories"
    },
    {
        name: "Outros",
        value: "outhers"
    },
]


const colorsOptions = [
    {
        name: "Amarelo",
        value: "Amarelo"
    },
    {
        name: "Vermelho",
        value: "Vermelho"
    },
    {
        name: "Azul",
        value: "Azul"
    },
    {
        name: "Verde",
        value: "Verde"
    },
    {
        name: "Vinho",
        value: "Vinho"
    },
    {
        name: "Marrom",
        value: "Marrom"
    },
    {
        name: "Preto",
        value: "Preto"
    },
    {
        name: "Branca",
        value: "Branca"
    },
    {
        name: "Cinza",
        value: "Cinza"
    },
    {
        name: "Rosa",
        value: "Rosa"
    },
    {
        name: "Bege",
        value: "Bege"
    },
    {
        name: "Colorido",
        value: "Colorido"
    },
    {
        name: "Laranja",
        value: "Laranja"
    },
    {
        name: "Roxo",
        value: "Roxo"
    },
    
]

interface Color {
    hash: any,
    name: any
}

interface Stock {
    name?: string,
    colors: Color[],
    sizes: any[],
    images: any[],
    type?: string,
    files: any[]
}

export default function EditProduct(){

    const [preview, setPreview] = useState([])
    const [product, setProduct] = useState<Stock | any>({colors: [], sizes: [], images: [], type: '',name: '', files: []})
    const {editProduct, getProductById, deleteImg} = ApiService()
    const {checkAuth} = useContext(Context)
    const navigate = useNavigate()
    const {id} = useParams()

    useEffect(() => {
        checkAuth()
        const fetchProduct = async () => { 
            const response = await getProductById(id); 
            setProduct(response.product);
        };    
        fetchProduct();         
    },[checkAuth])
   

    async function handleEditProduct(e: any){
        e.preventDefault()
     
        const formData = new FormData();
        
        Object.keys(product).forEach((key) => { 
            if(key === 'files'){
                if(product.files && product.files.length > 0){
                    product.files.forEach(function(file: any, index: any) {
                        formData.append('files', file); 
                    });
                }
            }
            else if (Array.isArray(product[key])) {
                formData.append(key, JSON.stringify(product[key]));
            } else {
                formData.append(key, product[key]);
            }
        });
                     
        const response = await editProduct(id, formData); 
    }

    function handleOnChange(e: any){
        setProduct({...product, [e.target.name]: e.target.value})
    }

    function onFileChange(e: any){
        setPreview(Array.from(e.target.files))
        setProduct({...product, files: [...e.target.files]})    
    }

    // FUNCTIONS IMAGE
    const deleteImage = async(index: any) => {
       
        const data = {id, index}
        const apague = await deleteImg(data)

        if (product) {
            const newImages = product.images.filter((_: any, i: any) => i !== index);
            const newProduct = { ...product, images: newImages };
            setProduct(newProduct);
        }
    };

    const deleteFile = (index: any) => {
        if (product) {
            const newFiles = product.files.filter((_: any, i: any) => i !== index);
            const newProduct = { ...product, files: newFiles };
            setProduct(newProduct);
        }
        
    };

    // FUNCTIONS COLORS
    const handleColor = () => {
        if (product) {
            const newProduct = { ...product, colors: [...product.colors, { hash: '', name: '' }] };
            setProduct(newProduct);
        }
    };

    const handleOnChangeColorHash = (e: any, index: any) => {
        if (product) {
            const newColors = product.colors.map((color: any, i: any) =>
                i === index ? { ...color, hash: e.target.value } : color
            );
            const newProduct = { ...product, colors: newColors };
            setProduct(newProduct);
        }
    };

    const handleOnChangeColorName = (e: any, index: any) => {
        if (product) {
            const newColors = product.colors.map((color: any, i: any) =>
                i === index ? { ...color, name: e.target.value } : color
            );
            const newProduct = { ...product, colors: newColors };
            setProduct(newProduct);
        }
    };

    const deleteColor = (index: any) => {
        if (product) {
            const newColors = product.colors.filter((_: any, i: any) => i !== index);
            const newProduct = { ...product, colors: newColors };
            setProduct(newProduct);
        }
    };

    // FUNCTIONS SIZES
    const handleSizes = () => {
        if (product) {
            const newProduct = { ...product, sizes: [...product.sizes, ''] };
            setProduct(newProduct);
        }
    };

    const deleteSize = (index: any) => {
        if (product) {
            const newSizes = product.sizes.filter((_: any, i: any) => i !== index);
            const newProduct = { ...product, sizes: newSizes };
            setProduct(newProduct);
        }
    };

    const handleOnChangeSizes = (e: any, index: any) => {
        if (product) {
            const newSizes = product.sizes.map((size: any, i: any) => (i === index ? e.target.value : size));
            const newProduct = { ...product, sizes: newSizes };
            setProduct(newProduct);
        }
    };

    return (
        <section className='bg-white flex-grow-1'>
            <div className="row h-100">
                <div className="col-12 col-md-6 align-self-stretch">
                    
                        <div className='position-relative h-100 bg-primary w-100 d-flex py-2'>
                            <button onClick={() => navigate(-1)} className="position-absolute btn btn-outline-white rounded-5 m-3 d-flex align-items-center"> <span className="material-icons-round d-flex">arrow_back_ios_new</span> Voltar</button>
                            <img src={background} alt="backgroud" className='w-100 d-none d-md-flex' />
                            <img src={backgroundMobile} alt="backgroud" className='w-100 d-flex d-md-none' />
                        </div>       
                
                </div>
                <div className='col-12 col-md-6'>
                    <div className='p-3 p-md-4'>
                        <form onSubmit={handleEditProduct} className='row g-2 g-md-3'>    
                                {product.images.map((image: any, index: any) => 
                                    <div key={`${index}image` } className='col-4 '> 
                                            <div className='position-relative'>
                                                <button
                                                        onClick={() => deleteImage(index)}
                                                        type='button'
                                                        className='btn btn-danger rounded-5 p-0 position-absolute end-0'
                                                    >
                                                <span className="material-icons-round d-flex fs-4">clear</span>
                                                </button>
                                                <img className='w-100 mt-1 object-fit-cover border border-2 border-light rounded-4 overflow-hidden' style={{height: '100px'}}  src={image} alt={product.name} key={`${product.name}+${index}`} />
                                            </div>  
                                            
                                    </div> 
                                )}      
                                {product.files && product.files.map((image: any, index: any) => 
                                    <div key={index} className='col-4'>    
                                        <div className='position-relative'>
                                                <button
                                                        onClick={() => deleteFile(index)}
                                                        type='button'
                                                        className='btn btn-danger rounded-5 p-0 position-absolute end-0'
                                                    >
                                                <span className="material-icons-round d-flex fs-4">clear</span>
                                                </button>
                                            <img className='w-100 object-fit-cover mt-1 border border-2 border-light rounded-4 overflow-hidden' style={{height: '100px'}}  src={URL.createObjectURL(image)} alt={product.name} key={`${product.name}+${index}`} />
                                        </div>     
                                    </div> 
                                ) }
                               
                                    <Input 
                                        type='file'
                                        name='files'
                                        text=''
                                        placeholder='Adicione fotos do produto'
                                        handleOnChange={onFileChange}
                                        multiple={true}
                                        
                                    />
                                    <Input 
                                        type='text'
                                        name='name'
                                        text='Nome do produto'
                                        placeholder='Digite o nome'
                                        handleOnChange={handleOnChange}
                                        value={product.name || ''}
                                    />
                                
                                    <Select
                                        name='type'
                                        text= 'Tipo do produto'
                                        handleOnChange={handleOnChange}
                                        options={types}
                                        value={product.type || ''}
                                    />

                                    <div className='col-12'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <label className='text-primary h5 m-0'>Escolha as cores disponíveis:</label>
                                            <button type='button' onClick={handleColor} className='btn btn-secondary p-1 rounded-5'>
                                                <span className="material-icons-outlined fw-bold d-flex fs-5">add</span>
                                            </button>
                                        </div>
                                        <div className='d-flex flex-wrap'>
                                            {product.colors.length > 0 && product.colors.map((color: any, index: any) => (
                                                
                                                <div key={index} className='position-relative mt-1'>

                                                        <button
                                                            onClick={() => deleteColor(index)}
                                                            type='button'
                                                            className='btn btn-danger rounded-5 p-0 me-2 position-absolute end-0'
                                                        >
                                                            <span className="material-icons-round d-flex fs-4">clear</span>
                                                        </button>



                                                    <div className='d-flex align-items-center border border-1 mt-2 rounded-5 p-1 me-2'>
                                                        <input
                                                            type="color"
                                                            value={color.hash}
                                                            onChange={(e) => handleOnChangeColorHash(e, index)}
                                                            style={{ width: '40px', height: '40px' }}
                                                            className='rounded-5 overflow-hidden'
                                                        />
                                                        <select
                                                            className='border-0 p-2 fs-5'
                                                            value={color.name}
                                                            onChange={(e) => handleOnChangeColorName(e, index)}
                                                        >
                                                            <option value="">Selecione o nome da cor</option>
                                                            {colorsOptions.map((option, key) => (
                                                                <option key={key} value={option.value}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                               
                                            ))}
                                        </div>
                                    </div>
                                            
                                    <div className='col-12'>
                                            <div className='d-flex justify-content-between align-items-center mb-1'>
                                                <label className='text-primary h5 m-0'>Escolha os tamanhos disponíveis:</label>
                                                <button type='button' onClick={handleSizes} className='btn btn-secondary p-1 rounded-5'>
                                                    <span className="material-icons-outlined fw-bold d-flex fs-5">add</span>
                                                </button>
                                            </div>
                                            <div className='d-flex flex-wrap'>
                                                {product.sizes.map((input: any, index: any) => (
                                                    <div className='position-relative' key={index}>
                                                        <button
                                                            type='button'
                                                            onClick={() => deleteSize(index)}
                                                            className='btn btn-danger rounded-5 p-0 position-absolute end-0'
                                                        >
                                                            <span className="material-icons-round d-flex fs-4">clear</span>
                                                        </button>
                                                        <input
                                                            type="text"
                                                            value={input}
                                                            onChange={(e) => handleOnChangeSizes(e, index)}
                                                            style={{ width: '50px', height: '50px' }}
                                                            className='rounded-5 overflow-hidden me-1 border border-light text-center text-uppercase mt-2'
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                    </div>


                                    <Input 
                                        step="0.01"
                                        col='6'
                                        type='number'
                                        name='price'
                                        text='Preço'
                                        placeholder='R$'
                                        handleOnChange={handleOnChange}
                                        value={product.price || ''}
                                    />  

                                    <Input 
                                        max={100}
                                        col='6'
                                        type='number'
                                        name='descont'
                                        text='Desconto'
                                        placeholder='%'
                                        handleOnChange={handleOnChange}
                                        value={product.descont || ''}
                                    />  
                                    <div className='col-12'>
                                        <input className="btn btn-secondary w-100 rounded-5 p-2 mt-3" type="submit" value="Salvar"/> 
                                    </div>

                        </form>
                    </div> 
                </div>
            </div>
            
        </section>
    )
}
