import { useState, useEffect } from "react";
import bus  from '../utils/bus'
import { useParams } from "react-router-dom";

function Message(){
    const [visibility, setVisibility] = useState(false)
    const [type, setType] = useState('')
    const [message, setMessage] = useState('')
    const [icon, setIcon] = useState('')
  

    useEffect(()=> {
     
        bus.addListener('flash', ({message, type, icon}) =>{
            setVisibility(true)
            setMessage(message)
            setType(type)
            setIcon(icon)

            setTimeout(() =>{
                setVisibility(false)
            }, 3000)
        })

    }, [])

    return (
        visibility ? (
                <div style={{zIndex: '1000'}} className={`rounded-4 position-absolute w-75 top-50 start-50 text-center translate-middle text-white p-3 border border-${type} bg-${type}`}>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <span className="material-icons-round d-flex fs-3 mb-1 fw-normal">{icon}</span>{message}
                    </div> 
                </div>
        ) : (
            <></>
        )
    )
}

export default Message