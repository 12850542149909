import Input from '../../components/Input'
import ApiService from '../../api/apiService'
import { useEffect, useState } from 'react'

export default function Banner(){
    const {newBanner, getBanners, deleteBanner} = ApiService()
    const [banners, setBanners] = useState([])
 
     useEffect(() => {
        console.log()
        async function fetchProducts() {
            try {
              const response = await getBanners();
              setBanners(response);
            } catch (error) {
              console.error('Erro ao buscar produtos:', error);
            }
          };
          fetchProducts();
     }, [])

    async function onFileChange(e: any){

        const formData = new FormData();
        
        formData.append('image', e.target.files[0]); 
        formData.append('device', e.target.name); 
            
        const response = await newBanner(formData); 
        setBanners(response)
 
    }

    async function removeBanner(id: any) {
        const response = await deleteBanner(id);
        setBanners(response)
    }

    return (
        <section className="row g-3">
            <div className="col-12">
                <div className='d-flex align-items-center'>
                    <i className="material-icons-round d-flex me-1 mt-1 fs-3">aspect_ratio</i>
                    <h3 className="fw-bold m-0">Banners</h3>
                </div>   
            </div>
            <div className="col-12 col-md-6 border-md-2 border-end">
                <div className='d-flex align-items-center mb-2'>
                    <h4 className='fw-normal m-0'>Para Desktop</h4>
                    <span className="material-icons d-flex text-secondary ms-1">help</span>
                </div>
                <Input 
                    type='file'
                    name='desktop'
                    text=''
                    placeholder='Adicione imagens'
                    handleOnChange={onFileChange}
                    multiple={false}
                />
                <div className="row g-2 mt-2">
                {banners
                    .filter((banner: any) => banner.device === 'desktop')
                    .map((banner: any, key: any) => (
                        <div className="col-6 position-relative" key={key}>
                            <button onClick={() => removeBanner(banner._id)}  className='btn btn-danger p-1 rounded-5 position-absolute end-0'>
                                <span className="material-icons-round d-flex fs-4">delete_forever</span>
                            </button>
                            <img src={banner.image}  className='w-100 object-fit-cover rounded-4 mt-3' alt="banners" />
                        </div>
                    ))
                }
                    

                </div>
            </div>
                <hr className='d-block d-md-none'/>
            <div className="col-12 col-md-6">
            <div className='d-flex align-items-center mb-2'>
                    <h4 className='fw-normal m-0'>Para celular</h4>
                    <span className="material-icons d-flex text-secondary ms-1">help</span>
                </div>
                
                    <Input 
                        type='file'
                        name='mobile'
                        text=''
                        placeholder='Adicione imagens'
                        handleOnChange={onFileChange}
                        multiple={false}
                    />
               
                <div className="row g-2 mt-2">
                {banners
                    .filter((banner: any) => banner.device === 'mobile')
                    .map((banner: any, key: any) => (
                        <div className="col-6 position-relative" key={key}>
                            <button onClick={() => removeBanner(banner._id)}  className='btn btn-danger p-1 rounded-5 position-absolute end-0'>
                                <span className="material-icons-round d-flex fs-4">delete_forever</span>
                            </button>
                            <img src={banner.image}  className='w-100 object-fit-cover rounded-4 mt-3' alt="banners" />
                        </div>
                    ))
                    }
                    
                </div>
            </div>


        </section>
    )
}