export function Insights(){
    return (
        <section>
            <div className='w-100 text-center px-3 bg-light bg-opacity-25 rounded-5 py-5 d-flex align-items-center flex-column justify-content-center'>
                    <span className="material-icons-outlined fs-1 text-light mb-2">pending</span>
                    <h5 className='fw-normal text-light m-0 mb-1'>Essa funcionalidade ainda está em desenvolvimento!</h5>
                    <h5 className='fw-bold text-light'>Em breve ela estará disponível para você.</h5>
            </div>
        </section>
    )
}