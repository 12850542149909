import { useContext, useEffect, useState } from 'react'
import background from '../../assets/background/fundoNewProduct.png'
import backgroundMobile from '../../assets/background/newProductMobile.png'
import Input from '../../components/Input'
import ApiService from '../../api/apiService'
import Select from '../../components/Select'
import { Context } from '../../context/UserContext'
import { useNavigate } from 'react-router-dom'

const types = [
    {
        name: "Camisetas",
        value: "tshirt"
    },
    {
        name: "Shorts",
        value: "shorts"
    },
    {
        name: "Calças",
        value: "pants"
    },
    {
        name: "Calçados",
        value: "footwear"
    },
    {
        name: "Acessórios",
        value: "accessories"
    },
    {
        name: "Outros",
        value: "outhers"
    },
]


const colorsOptions = [
    {
        name: "Amarelo",
        value: "Amarelo"
    },
    {
        name: "Vermelho",
        value: "Vermelho"
    },
    {
        name: "Azul",
        value: "Azul"
    },
    {
        name: "Verde",
        value: "Verde"
    },
    {
        name: "Vinho",
        value: "Vinho"
    },
    {
        name: "Marrom",
        value: "Marrom"
    },
    {
        name: "Preto",
        value: "Preto"
    },
    {
        name: "Branca",
        value: "Branca"
    },
    {
        name: "Cinza",
        value: "Cinza"
    },
    {
        name: "Rosa",
        value: "Rosa"
    },
    {
        name: "Bege",
        value: "Bege"
    },
    {
        name: "Colorido",
        value: "Colorido"
    },
    {
        name: "Laranja",
        value: "Laranja"
    },
    {
        name: "Roxo",
        value: "Roxo"
    },
    
]

interface Color {
    hash: any,
    name: any
}

interface Stock {
    name?: string,
    colors: Color[],
    sizes: any[],
    images: any[],
    type?: string,
}

export default function NewProduct(){

    const [preview, setPreview] = useState([])
    const [product, setProduct] = useState<Stock | any>({colors: [], sizes: [], images: [], type: '',name: ''})
    const [colors, setColors] = useState<JSX.Element[]>([]);
    const [sizes, setSizes] = useState<JSX.Element[]>([]);
    const {newProduct} = ApiService()
    const {checkAuth} = useContext(Context)
    const navigate = useNavigate()

    useEffect(() => {

        checkAuth()
                
    },[checkAuth] )
   

    async function handleNewProduct(e: any){
        e.preventDefault()

        const formData = new FormData();
        Object.keys(product).forEach((key) => {
            if (Array.isArray(product[key])) {
                formData.append(key, JSON.stringify(product[key]));
            } else {
                formData.append(key, product[key]);
            }
        });
        
        if(product.images.length > 0){
            product.images.forEach(function(file: any, index: any) {
                formData.append('images', file); 
            });
        }
        
        const response = await newProduct(formData); 
        if(response.emptyInput){
            setProduct({colors: [], sizes: [], images: [], type: '',name: ''})
            setColors([])
            setSizes([])
            setPreview([])
        }
    }

    function handleOnChange(e: any){
        setProduct({...product, [e.target.name]: e.target.value})
    }

    const handleColor = () => {
        const color = colors.length; 
        setColors([...colors,
                <div className='d-flex align-items-center border border-1 rounded-5 p-1'>
                    <input key={color} type="color" onChange={(e) => handleOnChangeColorHash(e, color)} style={{ width: '40px', height: '40px' }} className='rounded-5 overflow-hidden' name="colors" id={color.toString()} /> 
                    <select className='border-0 p-2 fs-5' name="" id="" onChange={(e) => handleOnChangeColorName(e, color)}>
                        <option value="Selecione">Selecione o nome da cor</option>
                        {
                            colorsOptions.map((color, key)=> (
                                <option key={key} value={color.value}>{color.name}</option>
                            ))
                        }
                    </select> 
                </div> ]);
    };

    const handleSizes = () => {
        const size = sizes.length; 
        setSizes([...sizes, <input key={size} type="text" onChange={(e) => handleOnChangeSizes(e, size)} style={{ width: '40px', height: '40px' }} className='rounded-5 overflow-hidden me-1 border border-light text-center' name="colors" id={size.toString()} />]);
    };


    function handleOnChangeColorHash(e: any, index: any) {
        const newColors = [...product.colors]; // Cria uma cópia do array de cores
        newColors[index] = {
            ...newColors[index],
            hash: e.target.value
        };
        product.colors = newColors; // Atualiza o array de cores no produto
    }

    function handleOnChangeColorName(e: any, index: any) {
        const newColors = [...product.colors]; // Cria uma cópia do array de cores
        newColors[index] = {
            ...newColors[index],
            name: e.target.value
        };
        product.colors = newColors; // Atualiza o array de cores no produto
    }

    function handleOnChangeSizes(e: any, index: any){
        product.sizes[index] = e.target.value
    }
    
    function onFileChange(e: any){
        setPreview(Array.from(e.target.files))
        setProduct({...product, images: [...e.target.files]})    
    }


    return (
        <section className='bg-white flex-grow-1'>
            <div className="row h-100">
                <div className="col-12 col-md-6 align-self-stretch">
                    
                        <div className='position-relative h-100 bg-primary w-100 d-flex'>
                            <button onClick={() => navigate(-1)} className="position-absolute btn btn-outline-white rounded-5 m-3 d-flex align-items-center"> <span className="material-icons-round d-flex">arrow_back_ios_new</span> Voltar</button>
                            <img src={background} alt="backgroud" className='w-100 d-none d-md-flex' />
                            <img src={backgroundMobile} alt="backgroud" className='w-100 d-flex d-md-none' />

                        </div>       
                
                </div>
                <div className='col-12 col-md-6'>
                    <div className='p-3 p-md-4'>
                        <form onSubmit={handleNewProduct} className='row g-2 g-md-3'>          
                             {preview.map((image, index) => 
                                <div key={index} className='col-4'>         
                                    <img className='w-100 object-fit-cover border border-2 border-light rounded-4 overflow-hidden' style={{height: '100px'}}  src={URL.createObjectURL(image)} alt={product.name} key={`${product.name}+${index}`} />
                                </div> 
                            ) }
                                    <Input 
                                        type='file'
                                        name='images'
                                        text=''
                                        placeholder='Adicione fotos do produto'
                                        handleOnChange={onFileChange}
                                        multiple={true}
                                        
                                    />
                                    <Input 
                                        type='text'
                                        name='name'
                                        text='Nome do produto'
                                        placeholder='Digite o nome'
                                        handleOnChange={handleOnChange}
                                        value={product.name || ''}
                                    />
                                
                                    <Select
                                        name='type'
                                        text= 'Tipo do produto'
                                        handleOnChange={handleOnChange}
                                        options={types}
                                        value={product.type || ''}
                                    />
                                    <div className='my-3'>
                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                            <label className='text-primary h5 m-0'>Escolha as cores disponíveis:</label>
                                            <button type='button' onClick={handleColor} className='btn btn-secondary p-1 rounded-5'><span className="material-icons-outlined fw-bold d-flex fs-5 ">add</span></button>
                                        </div>
                                        <div className='d-flex flex-wrap'>
                                            {colors.length > 0 && colors.map((input, index) => (
                                                <div key={index}>{input}</div>
                                            ))}
                                        </div>
                                    </div>
                                            
                                    <div className='my-3'>
                                        <div className='d-flex justify-content-between align-items-center '>
                                            <label className='text-primary h5'>Escolha os tamanhos disponíveis:</label>
                                            <button type='button' onClick={handleSizes} className='btn btn-secondary p-1 rounded-5'><span className="material-icons-outlined fw-bold d-flex fs-5 ">add</span></button>
                                        </div>
                                        <div className='d-flex flex-wrap'>
                                            {sizes.map((input, index) => (
                                                <div key={index}>{input}</div>
                                            ))}
                                        </div>
                                    </div>


                                    <Input 
                                        step="0.01"
                                        col='6'
                                        type='number'
                                        name='price'
                                        text='Preço'
                                        placeholder='R$'
                                        handleOnChange={handleOnChange}
                                        value={product.price || ''}
                                    />  

                                    
                                    <Input 
                                        max={100}
                                        col='6'
                                        type='number'
                                        name='descont'
                                        text='Desconto'
                                        placeholder='%'
                                        handleOnChange={handleOnChange}
                                        value={product.descont || ''}
                                    />  
                                    <div className='col-12'>
                                    <input className="btn btn-primary w-100 rounded-5 p-2 mt-3" type="submit" value="Cadastrar"/> 

                                    </div>

                        </form>
                    </div> 
                </div>
            </div>
            
        </section>
    )
}
