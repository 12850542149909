import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {   
    var element = document.getElementById("box");
    if (element && (pathname !== '/')) {
        console.log(element.getBoundingClientRect().top)
        element.scrollIntoView({ block: "end", behavior: "smooth" });
    }
}, [pathname])

  return null
}
