import { useState, useEffect } from "react";
import bus  from '../utils/bus'
import logo from '../assets/logos/logoPretaPS.png'
function Loading(){
    const [visibility, setVisibility] = useState(false)


    useEffect(()=> {
        bus.addListener('loading', ({visibility}: any) =>{
            setVisibility(visibility)
        })
    }, [])

    return (

        visibility ? (
                <div style={{zIndex: '1000', width: '100vw', height: '100vh'}} className='bg-white bg-opacity-50 position-absolute'>
                    <div className="d-flex align-items-center flex-column justify-content-center h-100 w-100">
                        <div className="position-relative d-flex align-items-center justify-content-center">
                            <div className="spinner-border text-primary position-absolute" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            <img src={logo} style={{width: '3em'}} alt="logo" />
                        </div>
                    </div>
                   
                </div>
        ) : (
            <></>
        )
    )
}

export default Loading