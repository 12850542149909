import { useEffect, useState } from "react"
import bus from "../utils/bus"

import Modal from 'react-bootstrap/Modal';
import Select from './Select'
import ApiService from "../api/apiService";

const colors = [
    {
        name: "Amarelo",
        value: "Amarelo"
    },
    {
        name: "Vermelho",
        value: "Vermelho"
    },
    {
        name: "Azul",
        value: "Azul"
    },
    {
        name: "Verde",
        value: "Verde"
    },
    {
        name: "Vinho",
        value: "Vinho"
    },
    {
        name: "Marrom",
        value: "Marrom"
    },
    {
        name: "Preto",
        value: "Preto"
    },
    {
        name: "Branca",
        value: "Branca"
    },
    {
        name: "Cinza",
        value: "Cinza"
    },
    {
        name: "Rosa",
        value: "Rosa"
    },
    {
        name: "Bege",
        value: "Bege"
    },
    {
        name: "Colorido",
        value: "Colorido"
    },
    {
        name: "Laranja",
        value: "Laranja"
    },
    {
        name: "Roxo",
        value: "Roxo"
    },
    
]


const marcas = [
    {
        name: "Nueve 3",
        value: "Nueve 3"
    },
    {
        name: "Arezzo",
        value: "Arezzo"
    },
    {
        name: "Nike",
        value: "Nike"
    },
    {
        name: "Lacoste",
        value: "Lacoste"
    },
    {
        name: "Adidas",
        value: "Adidas"
    },
    {
        name: "Kenner",
        value: "Kenner"
    },
]


export function ModalFilter({ onDataChange }: any){

    const {getFilter}= ApiService()
    const [visibility, setVisibility] = useState(false)
    const [filter, setFilter] = useState({color: '', price: 0, type: ''})

    useEffect(()=> { 
        bus.addListener('filter', ({visibility, category}: any) =>{
            setVisibility(visibility)
            setFilter({...filter, type: category})
        })
    }, [filter])

    function handleClose(){
        setVisibility(false); 
        setFilter({color: '', price: 0, type: ''}) 
    };


    function handleOnChange(e: any){
        setFilter({...filter, [e.target.name]: e.target.value})  
    }


    
    async function handleFilter(e: any){
        e.preventDefault()
        handleClose()
        const response = await getFilter(filter)
        onDataChange(response)
        return response
    }


    return (
        
        <Modal show={visibility} onHide={handleClose}  size="lg" >
        <Modal.Header closeButton className="px-0 px-md-2 py-2">
                    <div className='position-relative d-flex flex-column justify-content-center'>
                        <h4 className='fw-bold position-absolute m-0 text-nowrap d-flex align-items-center'><span className="material-icons-round d-flex">tune</span>Filtrar</h4>
                        <h3 className='text-light text-opacity-25 fw-bold m-0'>FILTRAR</h3>
                    </div>
        </Modal.Header>
        <Modal.Body className="px-0 px-md-2 py-2">
            <form onSubmit={handleFilter} className='row g-2'>
                    <Select
                        name='color'
                        text= 'Cor'
                        handleOnChange={handleOnChange}
                        options={colors}
                        value={filter.color || ''}
                    />
                    {/* <Select
                        name='mark'
                        text= 'Marca'
                        handleOnChange={handleOnChange}
                        options={marcas}
                        value={filter.mark || ''}
                    /> */}
                    <div>
                        <label htmlFor="customRange2" className="form-label h5">Preço</label>
                        <input type="range" onChange={handleOnChange} name='price'  className="form-range" min="0" max="1000" id="customRange2"></input>
                        <h5 className='fw-bold'><span className='fw-normal'>Até:</span>  R${filter.price},00</h5>
                    </div>
                    <div className='col-12'>
                        <button type='submit'  className="btn btn-primary rouded-5 w-100 p-2 rounded-5 d-flex justify-content-center mt-3 mt-md-4">Aplicar filtro</button>
                    </div>

            </form>
                        

        </Modal.Body>
    </Modal>
    )
}