import logo from '../assets/logos/logoFooter.png'

function Footer(){
    return (
        <footer className="px-3 px-md-5  py-4 bg-primary">
            <div className='row g-3 g-md-4'>
                <div className=' col-7 col-md-3 d-flex align-items-center mb-3 mb-md-0'>
                    <img src={logo} alt="" className='w-100' />
                </div>
                <div className='col-12 col-md-6 d-flex flex-column justify-content-center  border-2 border-end  border-start border-dark'>
                    <div className='d-flex'>
                        <i className='material-icons d-flex text-secondary'>place</i>
                        <h5 className='text-white ms-2 fw-normal'>R. Bento Urbano, 158 - Centro, 
                        São Paulo do Potengi - RN, 59460-000</h5>
                    </div>
                    <div className='d-flex mt-2'>
                        <i className='material-icons d-flex text-secondary'>schedule</i>
                        <h5 className='text-white m-0 ms-2 fw-normal'>Segunda a Sábado <br/> 7:30h às 18:00h</h5>
                    </div>
                </div>
                <div className='col-md-3 d-flex align-items-center'>

                    <a href="https://www.instagram.com/ps._multimarcas/" className='btn btn-outline-white rounded-5 p-2 me-2'>
                        <i className="bi bi-instagram d-flex fs-4"></i>
                    </a>
                    <a href="https://api.whatsapp.com/send/?phone=5584991010561&text&type=phone_number&app_absent=0" className='btn btn-outline-white rounded-5 p-2 me-2'>
                        <i className="bi bi-whatsapp d-flex fs-4"></i>
                    </a>
                    <a href="" className='btn btn-outline-white rounded-5 p-2'>
                        <i className="bi bi-envelope d-flex fs-4"></i>
                    </a>

                </div>
                <div className='col-12 text-center border-2 border-top border-dark pt-4'>
                    <h6 style={{letterSpacing: '3px'}} className='fw-normal m-0 text-white'>TODOS OS DIREITOS RESERVADOS <span className='fw-bold'>© 2024</span></h6>
                </div>
            </div>
        </footer>
    )
    
}
export default Footer