import styles from './Components.module.css'

function Container({children}: any){

    return (
        <section id='box' className={`flex-grow-1 ${styles.mt_container}`}>        
              {children}  
        </section>
    )
}
export default Container