import { useContext, useState } from 'react'
import background from '../../assets/background/fundoLoginPs.png'

import Input from '../../components/Input'
import { Context } from '../../context/UserContext'


function Login(){



    const [user, setUser] = useState({})
    const {doLogin} = useContext(Context)


    function handleOnChange(e: any){
        setUser({...user, [e.target.name]: e.target.value})
    }

    function handleSubmit(e: any){
        e.preventDefault()
        doLogin(user)
    }

    return (
        <section className="bg-white flex-grow-1">
            <div className="row h-100">
                <div className="col-12 col-md-6 bg-primary h-100 d-none d-md-flex">
                        <img src={background} alt="backgroud" className='w-100' />
                </div>
                <div className="col-12 col-md-6 bg-white d-flex align-items-center justify-content-center flex-column">
                    
                    <div className='w-75 py-5 py-md-2'>
                        <h2 className='fw-bold text-primary mb-3'>Login</h2>
                        <form  onSubmit={handleSubmit} className='w-100'>
                                <Input 
                                    type='email'
                                    name='email'
                                    text='E-mail'
                                    placeholder='Digite o seu e-mail'
                                    handleOnChange={handleOnChange}
                                />
                                <Input 
                                    type='password'
                                    name='password'
                                    text='Senha'
                                    placeholder='Digite a sua senha'
                                    handleOnChange={handleOnChange}
                                />
                                <input className="btn btn-primary w-100 rounded-5 p-2 mt-3 fs-5" type="submit" value="Entrar"/> 
                        </form>
                            

                    </div>
                    
                    
                </div>
            </div>
        </section>
    )
}

export default Login