import bus from '../utils/bus'

export default function useFilter(){
    function setFilter(visibility: boolean, category: any){
        bus.emit('filter', {
            visibility: visibility,
            category: category
        })
    }
    return { setFilter }
}

