import { useEffect, useState } from "react"
import bus from "../utils/bus"
import CartItem from './CartItem'
import Modal from 'react-bootstrap/Modal';


interface Cart {
    name: string;
    size: number; 
    color: any;
    image: string;
    price: number;  
    type: string;
}

const types: { [key in Cart['type']]: string } = {
    tshirt: "👕",
    shorts: "🩳",
    pants: "👖",
    caps: "🧢",
    footwear: "👞",
    accessories: "🕶️",
    outhers: "outros"
};

function MyCart(){

    

    const [visibility, setVisibility] = useState(false)
    const [cart, setCart] = useState<Cart[]>([])
    const [total, setTotal] = useState(0)
 

    const handleClose = () => setVisibility(false);



    useEffect(()=> {

        let myCart = JSON.parse(localStorage.getItem("cart") || "[]"); 

        setCart(myCart)

        let totaly= 0
        for(let i=0; i < myCart.length; i++){
            totaly = totaly + myCart[i].price
        }
        setTotal(totaly)

        bus.addListener('modal', ({visibility}: any) =>{
            setVisibility(visibility)
        })

    }, [visibility])

    function handleDeleteOfCart(index: any){
        const updatedCart = cart.filter((_, i) => i !== index);
        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        let myCart = JSON.parse(localStorage.getItem("cart") || "[]"); 

        let totaly= 0
        for(let i=0; i < myCart.length; i++){
            totaly = totaly + myCart[i].price
        }
        setTotal(totaly)
    }


    const handleFinishedCart = () => {

        let mensagem = '😄Olá, desejo comprar os seguintes produtos:\n\n';
        cart.forEach((item, index) => {
            mensagem += `${types[item.type]} *${item.name.toUpperCase()}* \n`;
            mensagem += `Imagem: ${item.image}\n`;
            mensagem += `Tamanho: *${item.size.toString().toUpperCase()}*\n`;
            mensagem += `Cor: *${item.color.name}*\n`;
            mensagem += `Preço: *R$ ${item.price.toFixed(2)}*\n\n`;
        });
        mensagem+= `---------------------------------------- \n`
        mensagem+= `💰*TOTAL: R$ ${total.toFixed(2)}*💰`

        const url = `https://api.whatsapp.com/send?phone=5584988716812&text=${encodeURIComponent(mensagem)}`;
        window.open(url, "_blank");
    };

    return (
        <>    
            <Modal show={visibility} onHide={handleClose}  size="lg" >
                <Modal.Header closeButton className="px-0 px-md-2 py-2">
                            <div className='position-relative d-flex flex-column justify-content-center'>
                                <h4 className='fw-bold position-absolute m-0 text-nowrap'>Minha sacola</h4>
                                <h3 className='text-light text-opacity-25 fw-bold m-0'>SACOLA</h3>
                            </div>
                </Modal.Header>
                <Modal.Body className="px-0 px-md-2 py-2">
                    {cart.length > 0 ?
                    (cart.map((cart: any, key: any) => (
                        <CartItem key={key} cartData={cart} index={key} handleClick={() => handleDeleteOfCart(key)}></CartItem>
                    ))) :
                    (
                        <div className='w-100 bg-light bg-opacity-25 rounded-5 py-4 px-3 text-center d-flex align-items-center flex-column justify-content-center'>
                            <span className="material-icons-round fs-1 text-light mb-2">production_quantity_limits</span>
                            <h5 className='fw-normal text-light m-0'>Você ainda não tem itens na sacola!</h5>
                        </div>
                    )    
                }   

                </Modal.Body>
                <Modal.Footer className="px-0 px-md-2 py-2">
                        <div className='d-flex w-100 justify-content-between mb-2'>
                                    <h4>TOTAL:</h4>
                                    <h3 className='fw-bold'>R${total.toFixed(2)}</h3>
                            </div>
                        <button type="button" onClick={handleFinishedCart} className="btn btn-success w-100 rounded-5 p-2 fw-semibold">FINALIZAR PEDIDO</button>
                </Modal.Footer>
            </Modal>
        </>      
    )
}
export default MyCart