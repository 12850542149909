import { Link } from 'react-router-dom'
import styles from './Components.module.css'

function Card({data}: {data: any}){
    return (
        <Link to={`/product/${data._id}`} className="col-6 col-md-3 text-decoration-none align-self-stretch">
            <div className={`bg-white shadow d-flex flex-column rounded-4 overflow-hidden h-100 ${styles.scale}`}>
                
                <img src={data.images[0]} className={`w-100 object-fit-cover bg-white ${styles.img_card}`} alt="" />
                {data.descont > 0 &&
                    <div className='d-flex ms-1' style={{marginTop: '-13px'}}>
                        <h6 className='px-2 py-1 bg-success rounded-5 m-0 text-white'>PROMOÇÃO</h6>
                    </div>
                }
                
                <div className="p-2 d-flex flex-column flex-grow-1 justify-content-between">
                    <h5 className="text-uppercase mb-2 mb-md-3 text-primary">{data.name}</h5>
                    <div>
                        {data.descont > 0 &&
                                <h6 className="text-light m-0" style={{textDecoration: 'line-through'}}> <span className='fw-normal'>De:</span> R${parseFloat(data.price).toFixed(2)}</h6>
                        }
                        <h4 className="text-primary fw-bold m-0"><span className="fs-6 fw-normal">Por:</span> <span className='fw-normal'>R$</span>{(parseFloat(data.price) * ((100 - data.descont)/100)).toFixed(2)}</h4>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default Card