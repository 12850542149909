// hooks
import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import useFlashMessage from "../../hooks/useFlashMessage";
import useModal from "../../hooks/useModal";
import styles from './Products.module.css'
// api
import ApiService from "../../api/apiService"

// swiper
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css';

// components
import Card from "../../components/Card";
import bus from "../../utils/bus";

export default function ShowProduct(){

    const [productChoosed, setProductChoosed] = useState({size: '', color: {hash: '', name: ''}, name: '', price: 0, image: '', type: ''});
    const [product, setProduct] = useState<any>({name: '', images: [], colors: [], sizes: [], descont: 0, price: 0, type: ''});
    const [recommended, setRecommended] = useState([])
    const {id} = useParams();
    const {setFlashMessage} = useFlashMessage();
    const [authenticated, setAuthenticated] = useState(false)

    const {setModal} = useModal();
    const {getProductById, deleteProduct}= ApiService()
    const navigate = useNavigate()

    useEffect(() => {

        bus.addListener('auth', ({authenticated}: any) =>{
            setAuthenticated(authenticated)
        })

        const fetchProduct = async () => { 
              const response = await getProductById(id); 
              setProduct(response.product);
              setRecommended(response.recommended)
          };    
          fetchProduct();    
    }, [id, authenticated])

    async function handleDelete(){
        const response = await deleteProduct(id)
    }

    function handleSizes(key: any){
        const price =  (product.price * ((100 - product.descont)/100))
        setProductChoosed({...productChoosed, size: product.sizes[key], name: product.name, price: price, image: product.images[0], type: product.type})
    }

    function handleColor(key: any){
        setProductChoosed({...productChoosed, color: product.colors[key]})
    }

    function addCart(){
        
        if(!productChoosed.color.hash){
            setFlashMessage('Escolha uma cor!', 'warning', 'error_outline')
            return
        }

        if(productChoosed.size === ''){
            setFlashMessage('Escolha um tamanho!', 'warning', 'error_outline')
            return
        }
        let cart = JSON.parse(localStorage.getItem("cart") || "[]"); 
        cart.push(productChoosed); 
        localStorage.setItem("cart", JSON.stringify(cart)); 
        setModal(true)
    }

    return (
        <>
        <div className="position-fixed end-0 bottom-0 left-0 right-0 w-100 bg-white d-flex justify-content-between" style={{zIndex: '200'}}>
            {!authenticated ? 
            ( 
                <button onClick={addCart}  className="btn btn-success w-100 p-2 py-3 d-flex d-md-none justify-content-center mt-3 mt-md-4">  <i className="material-icons d-flex">add_shopping_cart</i> Adicionar à sacola</button>
             
        ) 
            : (
                <>
                <Link to='edit' className="btn btn-outline-info rounded-4 border-2 p-2 d-flex d-md-none justify-content-center ms-2 me-1 my-3  flex-grow-1 mt-md-4"> <i className="material-icons d-flex">edit</i> Editar</Link>
                <button onClick={handleDelete} className="btn btn-outline-danger rounded-4 border-2  p-2 d-flex d-md-none justify-content-center me-2 ms-1 my-3 flex-grow-1 mt-md-4"> <i className="material-icons d-flex">delete</i> Excluir</button>
                </> 
            )}
        </div>

         <section className="bg-white px-3 px-md-5 py-3">
                <button onClick={() => navigate(-1)} className="btn btn-outline-primary rounded-5 mb-3 d-flex align-items-center"> <span className="material-icons-round d-flex">arrow_back_ios_new</span> Voltar</button>
            <div className="row g-3 g-md-4">
                    <div className="col-12 col-md-5">
                    <Swiper
                        loop={true}
                        autoplay={{delay: 2000, disableOnInteraction: false,}}
                        pagination={{clickable: true,}}
                        spaceBetween={50}
                        slidesPerView={1}
                        modules={[Autoplay, Pagination, Navigation]}
                    >
                        {product.images.length === 0 &&
                        <>
                        <SwiperSlide>
                        <img src={ 'https://t3.ftcdn.net/jpg/04/60/01/36/360_F_460013622_6xF8uN6ubMvLx0tAJECBHfKPoNOR5cRa.jpg' } className='w-100 h-100 object-fit-cover' alt="slides" />
                        </SwiperSlide>
                        <SwiperSlide>
                        <img src={ 'https://t3.ftcdn.net/jpg/04/60/01/36/360_F_460013622_6xF8uN6ubMvLx0tAJECBHfKPoNOR5cRa.jpg' } className='w-100 h-100 object-fit-cover' alt="slides" />
                        </SwiperSlide>
                        </>
                    
                        }
                        {
                        product.images.map((image: any, key: any) => (
                            <SwiperSlide key={key}>                    
                                <img className={`rounded-5 object-fit-cover w-100 ${styles.img_product}`} src={image} alt="" />
                            </SwiperSlide>
                        ))
                    }
                    </Swiper>
                    </div>
                    <div className="col-12 col-md-7">
                        {product.descont > 0 &&
                            <div className="d-flex mb-3">
                                <h6 className='px-3 py-1 border border-2 border-success  rounded-5 m-0 text-success'>PROMOÇÃO</h6>
                            </div>
                        }
                        
                        <h4 className="fw-bold text-uppercase mb-3">{product.name}</h4>

                        <div className="border-2 py-3 border-top border-light">
                            <h6 className="text-bold">CORES DISPONÍVEIS:</h6>
                            <div className="d-flex flex-wrap">
                                    {
                                        product.colors.map((color: any, key: any) =>(
                                            <button disabled={authenticated} key={key} className={`p-0 bg-white me-2 rounded-5 p-1 ${color.hash === productChoosed.color.hash ? 'border border-2 border-primary' : 'border border-light'}`} onClick={() => handleColor(key)}>
                                                <div className={`rounded-5  shadow-lg`} style={{width: '40px', height: '40px' ,backgroundColor: color.hash}}></div>
                                            </button>
                                        ))
                                    }
                            </div>

                            <h6 className="mt-3 text-bold">TAMANHOS DISPONÍVEIS:</h6>
                            <div className="d-flex flex-wrap">
                                {   
                                    product.sizes.map((size: any, key: any) =>(
                                        <button disabled={authenticated} key={key} className='p-0 bg-white border-0' onClick={() => handleSizes(key)}>
                                            <div className={`rounded-5 me-2 d-flex text-uppercase justify-content-center align-items-center border border-2 border-primary m-0 ${size === productChoosed.size ? 'bg-primary text-white' : ''}`} style={{width: '45px', height: '45px'}}>{size}</div>
                                        </button>
                                    ))
                                }
                            </div>
                            <div className="mt-4">
                                    {product.descont > 0 &&
                                            <h5 className="text-light m-0" style={{textDecoration: 'line-through'}}> <span className='fw-normal'>De:</span> R${parseFloat(product.price).toFixed(2)}</h5>
                                    }
                                    <h3 className="text-primary fw-bold m-0"><span className="fs-4 fw-normal">Por:</span>  R${(parseFloat(product.price) * ((100 - product.descont)/100)).toFixed(2)}</h3>
                            </div>

                            <div className="w-100 bg-white d-flex justify-content-between">
                                {!authenticated ? 
                                ( 
                                    <button onClick={addCart}  className="btn btn-success w-100 p-2 py-3 d-none d-md-flex justify-content-center mt-3 mt-md-4">  <i className="material-icons d-flex">add_shopping_cart</i> Adicionar à sacola</button>
                                
                            ) 
                                : (
                                    <>
                                    <Link to='edit' className="btn btn-outline-info rounded-4 border-2 p-2 d-none d-md-flex justify-content-center me-1 my-3  flex-grow-1 mt-md-4"> <i className="material-icons d-flex">edit</i> Editar</Link>
                                    <button onClick={handleDelete} className="btn btn-outline-danger rounded-4 border-2  p-2 d-none d-md-flex justify-content-center ms-1 my-3 flex-grow-1 mt-md-4"> <i className="material-icons d-flex">delete</i> Excluir</button>
                                    </> 
                                )}
                            </div>
                            
                        </div>
                    </div>
            </div>
            <hr className="my-3" />
            <div className='row'>
                    <div className='position-relative d-flex flex-column justify-content-center align-items-center'>
                        <h4 className='fw-bold position-absolute'>Recomendados para você</h4>
                        <h2 className='text-light text-opacity-25'>RECOMENDADOS</h2>
                    </div>
                    <div className="col-12">
                        
                        <Swiper
                            loop={true}
                            autoplay={{delay: 2000, disableOnInteraction: false,}}
                            pagination={{clickable: true,}}
                            spaceBetween={(window.innerWidth > 600) ? 20 : 5 }
                            slidesPerView={(window.innerWidth > 600) ? 4 : 2 }
                            modules={[Autoplay, Pagination, Navigation]}
                        >
                        {
                            recommended.map((recommended: any, key: any) => (
                                <SwiperSlide key={key}>   
                                    <Card data={recommended} key={key}></Card>
                                </SwiperSlide>
                            ))
                        }
                        </Swiper>
                    </div>
            </div>
        </section>

        </>
       
    )
}
