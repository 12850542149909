
function Select({text, name, options, handleOnChange, value, col, multiple}: { 
    text: string, 
    name: string,
    options: any, 
    handleOnChange?: any, 
    value?: string, 
    col?: string,
    multiple?: boolean
}){
    return (
        <div className={`d-flex flex-column col-${col}`}>
            <label className='text-primary h5 ' htmlFor={name}>{text}: </label>
            <select className='p-2 rounded-4 border border-light h6' name={name} id={name} onChange={handleOnChange} value={value || ''}>
                <option defaultValue={1}>Selecione uma opção</option>
                {options.map((option: any) => (
                    <option value={option.value} key={option.value}>{option.name}</option>
                ))}
            </select>
        </div>
    )
}

export default Select