import { Link } from "react-router-dom"
import { useEffect, useState } from "react";

// api
import ApiService from "../../api/apiService";

// chart.js
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables, ChartDataLabels);



export default function Stock(){
    const {getStock} = ApiService()
    const [countStock, setCountStock] = useState([]) 
    const [productStock, setProductStock] = useState([]) 
    const [colorsStock, setColorsStock] = useState([]) 
    const [totalStock, setTotalStock] = useState(0) 



    useEffect(() => {
        const fetchStock = async () => {
            const response = await getStock(); // Supondo que getStock() retorna o objeto esperado
            setCountStock(response.counts);
            setProductStock(response.products);
            setColorsStock(response.colors)
            setTotalStock(response.total)
        };

        fetchStock();
    }, []);


    useEffect(() => {
        if (countStock.length > 0) { // Verifica se countStock tem dados
            const  chart = new Chart('myChart', {
                type: 'doughnut',
                data: {
                  labels: productStock,
                    datasets: [{
                      label: 'ESTOQUE',
                      data: countStock,
                      backgroundColor: colorsStock,
                      borderColor: '#ececec',
                      borderWidth: 5,
                      borderRadius: 5,
                      hoverOffset: 15
                    }]
                },
                options: {
                  plugins: {
                      legend: {
                        display: false 
                   },
                   datalabels: {
                      formatter: (value, ctx) => {
                          let sum = 0;
                          let dataArr = ctx.chart.data.datasets[0].data;      
                          
                          dataArr.map((data: any) => (
                              sum = sum + data
                          ))
                          
                          let percentage = ((value/sum)*100).toFixed(0)+"%";
                          return percentage;
                        },
                      anchor: 'center', // Alinhamento do routulo
                      align: 'center',   // Alinhamento do routulo
                      display: true,  // Se é pra mostrar o rutulo
                      font: {
                        
                        weight: 'bold', // peso da font do rotulo
                        size: 15, // tamanho da font do rotulo
                        family: 'Montserrat',  // a familia da font do routulo
                      },
                      color: '#111',
                      
                },
                  },
                  
                  scales: {
                      x: {
                          display: false,
                          stacked: false,
                          grid: {
                            display: false // Oculta as linhas de grade no eixo x
                          }
                      },
                    y: {
                      display: false,
                      beginAtZero: true,
                      grid: {
                          display: false
                      }
                    }
                  }
                }})

            return () => {
                chart.destroy();
            };
        }
    }, [countStock, productStock]); // Dependência de countStock
    

    return (
            <section>
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-3">
                    <div className='d-flex align-items-center'>
                        <i className="material-icons-round d-flex me-1 mt-1 fs-3">inventory</i>
                        <h3 className="fw-bold m-0">Estoque</h3>
                    </div>
                    <Link to='/product/create' className="btn btn-success rounded-5 text-white py-2 px-3 mt-3 mt-md-0">Cadastrar produto</Link>
                </div>
                        <div className=" bg-light bg-opacity-25 rounded-5 p-3">
                            <div className="row">
                                <div className="col-12 col-md-4 d-flex align-items-center text-center justify-content-center">
                                    <h2 className="fw-bold m-0 lh-1 mb-3 mb-md-0">{totalStock} <span className="fw-normal fs-3">PRODUTOS</span> </h2>      
                                </div>
                                <div className="col-12 col-md-4">   
                                        <canvas id="myChart"></canvas>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="d-flex h-100 flex-wrap flex-md-column justify-content-around mt-3 mt-md-0">
                                        {productStock.map((data: any, index: any) => (
                                            <div key={index} className="d-flex align-items-center">
                                            <i style={{color: colorsStock[index]}} className="material-icons d-flex fs-5 me-2">circle</i>
                                            <h5 className="m-0 text-normal">{data}</h5>
                                            </div>
                                        ))}
                                        {/* <div className="d-flex align-items-center">
                                            <i style={{color: '#46b3b5'}} className="material-icons d-flex fs-5 me-2">circle</i>
                                            <h5 className="m-0 text-normal">CAMISAS</h5>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <i style={{color: '#3669c2'}} className="material-icons d-flex fs-5 me-2">circle</i>
                                            <h5 className="m-0 text-normal">CALÇÃO</h5>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <i style={{color: '#00e18b'}} className="material-icons d-flex fs-5 me-2">circle</i>
                                            <h5 className="m-0 text-normal">CALÇAS</h5>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <i style={{color: '#feba27'}} className="material-icons d-flex fs-5 me-2">circle</i>
                                            <h5 className="m-0 text-normal">CALÇADOS</h5>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <i style={{color: '#e62555'}} className="material-icons d-flex fs-5 me-2">circle</i>
                                            <h5 className="m-0 text-normal">BONÉS</h5>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <i style={{color: '#db88b8'}} className="material-icons d-flex fs-5 me-2">circle</i>
                                            <h5 className="m-0 text-normal">ACESSÓRIOS</h5>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <i style={{color: '#935c9c'}} className="material-icons d-flex fs-5 me-2">circle</i>
                                            <h5 className="m-0 text-normal">OUTROS</h5>
                                        </div> */}
                                    </div>
                                    

                                </div>
                                
                            </div>
                        </div> 
            </section>
    )
}