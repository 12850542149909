import { useContext, useEffect } from "react";
import { Context } from "../../context/UserContext";
import { Routes, Route} from 'react-router-dom'

import SideBar from "../../components/SideBar";
import Banner from "./Banner";
import Stock from "./Stock";
import { Profile } from "./Profile";
import { Sellers } from "./Sellers";
import { Insights } from "./Insights";

function Admin() {    
        const { checkAuth} = useContext(Context)
    
        useEffect(() => {
            checkAuth()
        }, [checkAuth])
     
        return (
            <section className="bg-white h-100">
                <div className="row g-0 h-100">
                    <div className="col-3 d-none d-md-block ">
                        <SideBar/>
                    </div>
                    <div className="col-12 col-md-9">
                        <div className="px-3 py-4 p-md-4">
                            <Routes>
                                <Route path='profile' element={<Profile/>}/> 
                                <Route path='banner' element={<Banner/>}/> 
                                <Route path='insights' element={<Insights/>}/> 
                                <Route path='sellers' element={<Sellers/>}/> 
                                <Route path='/' element={<Stock/>}/> 
                            </Routes>
                        </div>                    
                    </div>        
                </div> 
            </section>
        );
    
}

export default Admin
