
function Input({ type, text, name, placeholder, handleOnChange, value, multiple, col, step, max }: { 
    type: string, 
    text: string, 
    name: string, 
    placeholder: string, 
    handleOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void, 
    value?: string, 
    multiple?: boolean ,
    col?: string,
    step?: any,
    max?: number
}) {
    if (type !== "file") {
        return (
            <>  
                 
                <div className={`d-flex flex-column col-${col}`}>
                    <label className='text-primary h5' htmlFor={name}>{text}:</label>
                    <input
                    className='p-2 rounded-4 border border-2 border-light h6'
                    max={max}
                    type={type}
                    name={name}
                    id={name}
                    placeholder={placeholder}
                    onChange={handleOnChange}
                    value={value}
                    step={step}/>           
                  
                </div>
            </>
        );
    } else {
        const handleClick = () => {
            const imagesElement = document.getElementById(`${name.toString()}`);

            if (imagesElement !== null) {
                imagesElement.click();
            } else {
                console.error("Elemento 'images' não encontrado.");
            }
          };
        return (
            <>
            

            <div className='d-flex flex-column'>
                <div onClick={handleClick} className="cursor-pointer rounded-4 p-2 d-flex justify-content-center align-items-center" style={{ border: '2px dashed #111'}}>
                    <i className="material-icons d-flex text-primary">add_photo_alternate</i>
                    <h5 className="m-0 text-primary ms-2 mt-1">{placeholder}</h5>
                </div>
                <input  
                        accept=".png, .jpeg, .jpg"
                        className='p-2 rounded-3 border border-primary h5'
                        type={type}
                        name={name}
                        id={name}
                        placeholder={placeholder}
                        onChange={handleOnChange}
                        value={value}
                        style={{ display: 'none' }}
                        {...(multiple ? { multiple } : '')} />
               
            </div>
        </>
        );
    }
}

export default Input;
