import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ApiService from '../../api/apiService';
import Card from '../../components/Card';

export default function SearchProduct(){

    const [products, setProducts] = useState([])
    const {search} = useParams()
    const {getSearch} = ApiService()
    useEffect(()=> {
        
        handleSearch()
    }, [search])

    async function handleSearch(){
        const formData = { name: search }
        const response = await getSearch(formData);
        setProducts(response)
        return response;
    }

    return (
        <section>
                    
                <div className='bg-white px-3 px-md-5 py-4'>

                    <div className='position-relative d-flex flex-column justify-content-center mb-3'>
                        <h3 className='fw-bold position-absolute m-0'>Resultado da pesquisa</h3>
                        <h1 className='text-light text-opacity-25 m-0'>RESULTADO</h1>
                        {/* <button onClick={() => setFilter(true, category)} className='position-absolute end-0 top-50 translate-middle-y   btn btn-outline-primary rounded-5 d-flex align-items-center'><span className="material-icons-round d-flex">tune</span>Filtrar</button>         */}

                    </div>

                    <div className='row g-0 g-md-3'>
                        {products.length > 0 ?(
                            products.map((product: any, key: any) => (
                                <Card data={product} key={key}></Card>
                            ))
                        ) : 
                        (
                            <div className='w-100 bg-light bg-opacity-25 rounded-5 py-5 d-flex align-items-center flex-column justify-content-center'>
                                <span className="material-icons-round fs-1 text-light mb-2">production_quantity_limits</span>
                                <h5 className='fw-normal text-light m-0 mb-1'>Não temos estoque no momento!</h5>
                                <h5 className='fw-bold text-light'>Em breve teremos muitas novidades.</h5>
                            </div>
                        )}
                        
                    </div>
                    

                </div>
        </section>
        
    )
}