
function CartItem({cartData, index, handleClick}: {cartData: any, index: any, handleClick: any}){

    return (
        <section className="rounded-5 border border-2 bg-white mb-2 overflow-hidden">
            <div className="row g-1">
                <div className="col-3 col-md-2 d-md-flex align-items-center justify-content-center">
                    <img className="object-fit-cover w-100 d-block d-md-none" style={{height: '100%'}} src={cartData.image} alt="camisa" />
                    <img className="rounded-5 object-fit-cover d-none d-md-flex" style={{width: '65px', height: '65px'}} src={cartData.image} alt="camisa" />
                </div>
                <div className="col-9 col-md-10">
                        <div className="p-2 d-md-flex justify-content-between w-100">
                            <div>
                                <h5 className="fw-bold text-uppercase">{cartData.name}</h5>

                                <div className="d-flex align-items-center border rounded-5 p-1">
                                <div className="d-flex align-items-center">
                                    <h6 className="m-0 mt-1">COR:</h6>
                                    <div className="rounded-5 ms-1 shadow-lg" style={{width: '20px', height: '20px' ,backgroundColor: cartData.color.hash}}></div>
                                </div>
                                <div className="d-flex align-items-center ms-3">
                                    <h6 className="m-0 mt-1">TAMANHO:</h6>
                                    <div className="rounded-5 ms-1 d-flex justify-content-center text-uppercase align-items-center border border-primary m-0 px-1 fs-6">{cartData.size}</div>
                                </div>
                                </div>
                            </div>

                            <div className="d-flex align-items-center mt-2 mt-md-0">
                                <h4 className="text-primary m-0 me-3 ">R${cartData.price.toFixed(2)}</h4>
                                <div className="d-flex flex-column">
                                    <button onClick={handleClick}  className="btn btn-danger p-1 rounded-5"> <i className="material-icons d-flex fs-4">delete</i> </button>
                                </div>
                            </div>
                        </div>               
                </div>
            </div>

        </section>
    )
}

export default CartItem