import { Link, NavLink, useNavigate } from 'react-router-dom'
import logo from '../assets/logos/logoPS.png'
import styles from './Components.module.css'
import { useEffect, useState } from 'react'
import useModal from '../hooks/useModal'
import {IonMenuButton} from '@ionic/react';
import bus from '../utils/bus'



function NavBar(){
    const [authenticated, setAuthenticated] = useState(false)
    const [search, setSearch] = useState<{ name: string }>({ name: '' });
    const navigate = useNavigate()
    interface CartItem {
        size: string;
        color: string;
        name: string;
        price: number;
        image: string;
    }

    const {setModal} = useModal()
    const [cart, setCart] = useState<CartItem[]>([]);
    useEffect(() => {
        bus.addListener('auth', ({authenticated}: any) =>{
            setAuthenticated(authenticated)
        })

        const savedCart = localStorage.getItem('cart');
        if (savedCart) {
            const parsedCart = JSON.parse(savedCart);
            setCart(parsedCart);
        }
    }, [localStorage.getItem('cart'), authenticated]);


  

    async function handleSearch(e: any){
        e.preventDefault();
        navigate(`product/search/${search.name}`)
        setSearch({name: ''})
        return
    }

    function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSearch({ ...search, [e.target.name]: e.target.value });
    }
    

    return (
        <div>
            <section className='position-fixed top-0 start-0 end-0' style={{zIndex: '10'}}>
                <div className='bg-white px-3 px-md-5 py-3'>
                    <div className=' d-flex justify-content-between align-items-center'>
                        <a href='/'> <img src={logo} alt="logo" className={styles.logo}/></a>
                    

                            <form onSubmit={handleSearch} className="border border-primary rounded-5 overflow-hidden d-none d-md-flex mx-3 w-100">
                                <input 
                                    onChange={handleOnChange} 
                                    type="text" 
                                    className="form-control border-0 fs-6 ms-2" 
                                    placeholder="O que você está procurando?" 
                                    name='name' 
                                    value={search.name || ''}
                                />
                                <button type='submit' className="input-group-text bg-white" id="basic-addon1">
                                    <i className='material-icons d-flex'>search</i>
                                </button>
                            </form>

                            <div className='d-flex align-items-center'>
                                {!authenticated &&
                                    <button onClick={() => setModal(true)} className='p-0 bg-white border-0 position-relative d-flex justify-content-center align-items-center me-2'>
                                        <h5 className='text-white fw-bold mt-3 ms-2 bg-success rounded-4 p-1 position-absolute m-0 lh-1'>{cart.length}</h5>
                                        <i className="material-icons-outlined text-primary fs-3 d-none d-md-flex"> shopping_bag </i>
                                        <i className="material-icons-outlined text-primary fs-1 d-flex d-md-none"> shopping_bag </i>
                                    </button>
                                }
                                

                                {authenticated ?
                                ( 
                                <div id="main-content" color="black" className="d-flex d-md-none text-primary">
                                    <IonMenuButton></IonMenuButton>
                                </div>
                                )
                                : (
                                    <NavLink to='/admin' className='text-decoration-none d-block d-md-none'><i className="material-icons-round text-primary fs-1 d-flex">account_circle</i> </NavLink>
                                    )
                                
                                }
                                <NavLink to='/admin' className='text-decoration-none d-none d-md-block'><i className="material-icons-round text-primary fs-3 d-flex">account_circle</i> </NavLink>
                            </div>
                    
                    </div>
                    <form onSubmit={handleSearch} className="border border-primary rounded-5 overflow-hidden d-flex d-md-none mt-2">
                        <input 
                            onChange={handleOnChange} 
                            type="text" 
                            className="form-control border-0 fs-6 ms-2" 
                            placeholder="O que você está procurando?" 
                            name='name' 
                            value={search.name || ''}
                        />
                        <button type='submit' className="input-group-text bg-white" id="basic-addon1">
                            <i className='material-icons d-flex'>search</i>
                        </button>
                    </form>
                </div>
               
                <div className='bg-primary ps-3 px-md-5 d-flex justify-content-between align-items-center'>
                    <ul className={`nav nav-underline justify-content-between w-100 d-flex flex-row flex-nowrap overflow-x-scroll `}>
                        <li className="nav-item px-1 mt-1 me-3"><NavLink className='nav-link fs-6' to="/tshirt">CAMISAS</NavLink></li>
                        <li className="nav-item px-1 mt-1 me-3"><NavLink className='nav-link fs-6' to="shorts">CALÇÃO</NavLink></li>
                        <li className="nav-item px-1 mt-1 me-3"><NavLink className='nav-link fs-6' to="pants">CALÇAS</NavLink></li>
                        <li className="nav-item px-1 mt-1 me-3"><NavLink className='nav-link fs-6' to="footwear">CALÇADOS</NavLink></li>
                        <li className="nav-item px-1 mt-1 me-3"><NavLink className='nav-link fs-6' to="caps">BONÉS</NavLink></li>
                        <li className="nav-item px-1 mt-1 me-3"><NavLink className='nav-link fs-6' to="accessories">ACESSÓRIOS</NavLink></li>
                        <li className="nav-item px-1 mt-1"><NavLink className='nav-link fs-6' to="outhers">OUTROS</NavLink></li>
                    </ul>
                </div>

            </section>


        </div>
        
    )
}
export default NavBar