// hooks
import { useLocation, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import useFilter from '../../hooks/useFilter';

// components
import Card from '../../components/Card'
import styles from './Products.module.css'
import { ModalFilter } from '../../components/ModalFilter';

// api
import ApiService from "../../api/apiService";
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

interface Cart {
    name: string;
    size: number; 
    color: string;
    image: string;
    price: number;  
    type: string;
}

const types: { [key in Cart['type']]: string } = {
    tshirt: "Camisetas",
    shorts: "Shorts",
    pants: "Calças",
    footwear: "Calçados",
    caps: "Bonés",
    accessories: "Acessórios",
    outhers: "Outros",
    value: "outhers"
}


export default function Products(){
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);

    const location = useLocation();
    const { category } = useParams();
    const [products, setProducts] = useState([])
    const [banners, setBanners] = useState([{image: '', device: ''}, {image: '', device: ''}])
    const {getProducts, getBanners} = ApiService()
    const [deviceType, setDeviceType] = useState('desktop');
    const {setFilter} = useFilter()
    let filteredBanners = [{image: '', device: ''}, {image: '', device: ''}]
    filteredBanners = banners.filter(banner => banner.device === deviceType);

    useEffect(() => {   
        fetchProducts();
        fetchBanners();
    }, [location])


    useEffect(() => {
        const handleResize = () => {
            if (window.matchMedia("(max-width: 768px)").matches) {
                setDeviceType('mobile');
            } else {
                setDeviceType('desktop');
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    async function fetchProducts(){
        try {
          const response = await getProducts(category);
          setProducts(response);
        } catch (error) {
          console.error('Erro ao buscar produtos:', error);
        }
    };

    async function fetchBanners(){
        try {
          const response = await getBanners();
          setBanners(response)
        } catch (error) {
          console.error('Erro ao buscar produtos:', error);
        }
    };

    const handleChildDataChange = (data: any) => {
        setProducts(data);
    };


    return (
        <section ref={scrollContainerRef}>
                
                <ModalFilter onDataChange={handleChildDataChange}/>
                { !category &&
                <div className={`w-100 ${styles.img_slide}`}>
                    <Swiper
                    className='h-100'
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    spaceBetween={50}
                    slidesPerView={1}
                    modules={[Autoplay, Pagination, Navigation]}
                > 
                    <Swiper>
                            {filteredBanners.length === 0 &&
                                <SwiperSlide>
                                <img src={ 'https://t3.ftcdn.net/jpg/04/60/01/36/360_F_460013622_6xF8uN6ubMvLx0tAJECBHfKPoNOR5cRa.jpg' } className='w-100 h-100 object-fit-cover' alt="slides" />
                                </SwiperSlide>
                            }
                            {filteredBanners.map((banner, key) => (
                                <SwiperSlide key={key}>
                                    <img src={banner.image ? banner.image : 'https://t3.ftcdn.net/jpg/04/60/01/36/360_F_460013622_6xF8uN6ubMvLx0tAJECBHfKPoNOR5cRa.jpg' } className='w-100 h-100 object-fit-cover' alt="slides" />
                                </SwiperSlide>
                            ))}
                    </Swiper>
                    
                    </Swiper>
                </div>
                }
                    
                <div className='bg-white px-3 px-md-5 py-4'>

                    <div className='position-relative d-flex flex-column justify-content-center mb-3'>
                        <h3 className='fw-bold position-absolute m-0'>{category ? types[category] : 'Novidades'}</h3>
                        <h1 className='text-light text-opacity-25 m-0'>{category ? types[category] : 'Novidades'}</h1>
                        <button onClick={() => setFilter(true, category)} className='position-absolute end-0 top-50 translate-middle-y   btn btn-outline-primary rounded-5 d-flex align-items-center'><span className="material-icons-round d-flex">tune</span>Filtrar</button>        

                    </div>

                    <div className='row g-0 g-md-3'>
                        {products.length > 0 ?(
                            products.map((product: any, key: any) => (
                                <Card data={product} key={key}></Card>
                            ))
                        ) : 
                        (
                            <div className='w-100 bg-light bg-opacity-25 rounded-5 py-5 d-flex align-items-center flex-column justify-content-center'>
                                <span className="material-icons-round fs-1 text-light mb-2">production_quantity_limits</span>
                                <h5 className='fw-normal text-light m-0 mb-1'>Não temos estoque no momento!</h5>
                                <h5 className='fw-bold text-light'>Em breve teremos muitas novidades.</h5>
                            </div>
                        )}
                        
                    </div>
                    

                </div>
        </section>
    )
}
