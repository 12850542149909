import bus from '../utils/bus'

export default function useModal(){
    function setModal(visibility: any){
        bus.emit('modal', {
            visibility: visibility,
        })
    }
    return { setModal }
}

