import bus from '../utils/bus'

export default function useAuth(){
    function setAuthenticated(authenticated: boolean){
        bus.emit('auth', {
            authenticated: authenticated,
        })
    }
    return { setAuthenticated }
}

