import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// COMPONENTS
import NavBar from './components/NavBar';
import Message from './components/Message';
import Loading from './components/Loading';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

// PAGES
import Login from './pages/Admin/Login';
import Admin from './pages/Admin/Admin';
import NewProduct from './pages/Product/NewProduct';
import Products from './pages/Product/Products';
import ShowProduct from './pages/Product/ShowProduct';
import EditProduct from './pages/Product/EditProduct';
import SearchProduct from './pages/Product/SearchProduct';

// CONTEXT
import { UserProvider } from './context/UserContext';
import Container from './components/Container';
import MyCart from './components/MyCart';

import { IonContent, IonMenu } from '@ionic/react';
import SideBar from './components/SideBar';
import bus from './utils/bus';

function App() {
  const [authenticated, setAuthenticated] = useState();

  useEffect(() => {
    bus.addListener('auth', ({ authenticated }) => {
      setAuthenticated(authenticated);
    });
  }, []);

  return (
    <Router>
      <UserProvider>
        <Loading />
        {authenticated && (
          <IonMenu contentId="main-content">
            <IonContent className="ion-padding p-0">
              <SideBar />
            </IonContent>
          </IonMenu>
        )}
        <NavBar />
        <Message />
        <Container>
        <ScrollToTop />
          <MyCart />
          <Routes>
            <Route path='product/search/:search' element={<SearchProduct />} />
            <Route path='/:category' element={<Products />} />
            <Route path='/login' element={<Login />} />
            <Route path='/admin/*' element={<Admin />} />
            <Route path='/product/:id/edit' element={<EditProduct />} />
            <Route path='/product/edit/:id' element={<EditProduct />} />
            <Route path='/product/create' element={<NewProduct />} />
            <Route path='/product/:id' element={<ShowProduct />} />
            <Route path='/' element={<Products />} />
            <Route path="*" element={<Products />} />
          </Routes>
        </Container>
        <Footer />
      </UserProvider>
    </Router>
  );
}

export default App;
