import { createContext } from "react";
import ApiService from "../api/apiService";


const Context = createContext()

function UserProvider({children}){
    const {doLogin, checkAuth, logout} = ApiService()

    return(
        <Context.Provider value={{doLogin, checkAuth, logout}}>
            {children}
        </Context.Provider>
    )
}

export {Context, UserProvider}