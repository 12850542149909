import axios from 'axios'

import useLoading from '../hooks/useLoading'
import useFlashMessage from '../hooks/useFlashMessage'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import useAuth from '../hooks/useAuth'


export const api = axios.create({
    baseURL: 'https://psmultimarcas.netlify.app/',
})

export default function ApiService(){

    const {setLoading} = useLoading()
    const {setFlashMessage} = useFlashMessage()
    const {setAuthenticated} = useAuth()
    const navigate = useNavigate()
    

    // verify has token to autecate
    useEffect(() => {
        const token = localStorage.getItem('token')
        if(token){
            setAuthenticated(true)
            api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`
        }
        }, [])

    async function doLogin(user: any) {
        setLoading(true);
        try {
            const response = await api.post('/users/login', user);
            setLoading(false)
            setAuthenticated(true)
            authUser(response.data)
            return response.data;
        } catch (error: any) {
            setLoading(false)
            setFlashMessage(error.response.data.message, 'danger')
            return error
        }

    }

    async function getProducts(category: any) {
        setLoading(true);
        try {
            const response = await api.get(`products/filter/${category}`).then((response) => {
                setLoading(false)
                return response.data.products;
            });
            return response; 
        } catch (error) {
            setLoading(false)
            return []; 
        }
    }

    async function getProductById(id: any) {
        setLoading(true);
        try {
            const response = await api.get(`/products/${id}`)
            setLoading(false);
            return response.data
        }catch(error: any){
            setLoading(false);
            setFlashMessage(error.response.data.message, 'danger' )
            navigate('/')
            return {}
        }
    }

    async function getFilter(filter: any) {
        setLoading(true)
        try {
            const response = await api.post(`products/filter`, filter)
            setLoading(false)
            return response.data
        }catch(error: any){
            setLoading(false)
            setFlashMessage(error.response.data.message, 'danger')
            return []
        }
    }

    async function getBanners() {
        setLoading(true);
        try {
            const response = await api.get('banners')
            setLoading(false)
            return response.data.banners;
        } catch (error) {
            setLoading(false)
            return []; 
        }
    }

    async function getSearch(name: any) {
        setLoading(true)
        try {
            const response = await api.post(`products/search`, name, )
            setLoading(false)
            return response.data.results
        }catch(error: any){
            setLoading(false)
            setFlashMessage(error.response.data.message, 'danger')
            return []
        }
    }

    async function getStock() {
        setLoading(true);
        try {
            const response = await api.get('products/stocks')
            setLoading(false)
            return response.data.stock;
        } catch (error) {
            setLoading(false)
            return []; 
        }
    }

    async function newProduct(product: any){
        setLoading(true)
        try {
            const response = await api.post('/products/create', product)
            setFlashMessage(response.data.message, 'success')
            setLoading(false)
            return response.data
        }catch(error: any){
            setLoading(false)
            setFlashMessage(error.response.data.message, 'danger')
            return []
        }
    }

    async function newBanner(banner: any) {
        setLoading(true)
        try {
            const response = await api.post('/banners/create', banner)
            setFlashMessage(response.data.message, 'success')
            setLoading(false)
            return response.data.banners
        }catch(error: any){
            setLoading(false)
            setFlashMessage(error.response.data.message, 'danger')
            return []
        }
    }

    async function deleteBanner(id: any) {
        setLoading(true)
        try {
            const response = await api.delete(`/banners/delete/${id}`, id)
            setFlashMessage(response.data.message, 'success', 'check_circle')
            setLoading(false)
            return response.data.banners
        }catch(error: any){
            setLoading(false)
            setFlashMessage(error.response.data.message, 'danger')
            return []
        }
    }

    async function deleteImg(data: any) {
        
        setLoading(true)
        try {
            const response = await api.patch(`/products/delete/image`, data)
            setFlashMessage(response.data.message, 'success', 'check_circle')
            setLoading(false)
            return response.data
        }catch(error: any){
            setLoading(false)
            setFlashMessage(error.response.data.message, 'danger')
            return []
        }
    }

    async function deleteProduct(id: any) {
        setLoading(true)
        try {
            const response = await api.delete(`/products/${id}/delete`)
            setFlashMessage(response.data.message, 'success', 'check_circle')
            setLoading(false)
            navigate('/')
            return response.data
        }catch(error: any){
            setLoading(false)
            setFlashMessage(error.response.data.message, 'danger')
            return []
        }
    }
 
    async function editProduct(id: any, product:any) {
        setLoading(true)
        try {
            const response = await api.patch(`/products/${id}/edit`, product, { headers: {
                'Content-Type': 'multipart/form-data',
              }})
            setFlashMessage(response.data.message, 'success', 'check_circle')
            setLoading(false)
            navigate(`/product/${id}`)
            return response.data
        }catch(error: any){
            setLoading(false)
            setFlashMessage(error.response.data.message, 'danger')
            return []
        }
    }

    // auxiliary functions
    function authUser(data: any){
        localStorage.setItem('token', JSON.stringify(data.token))
        navigate('/admin')
    }

    function checkAuth(){
        const token = localStorage.getItem('token')

        if(token){
            api.defaults.headers.Authorization =  `Bearer ${JSON.parse(token)}`
            return true
        }else{
            navigate('/login')
            return false
        }
    }

    function logout(){
        setAuthenticated(false)
        localStorage.removeItem('token')
        api.defaults.headers.Authorization = ''
        navigate('/')
    }

    
   
    return {doLogin, getProducts, getProductById, checkAuth, logout, newProduct, newBanner, getBanners, deleteBanner, getFilter, editProduct, getSearch, deleteImg, deleteProduct, getStock}
}









